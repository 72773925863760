import { Flex, Label, useStyles } from '@archipro-design/aria';
import { Divider } from './Divider';
import * as S from './MainInfoRightSectionItem.style';

interface ItemProps {
    label: string;
    children: React.ReactNode;
    className?: string;
}

export const MainInfoRightSectionItem: React.FC<ItemProps> = ({
    label,
    children,
    className = '',
}) => {
    const { css } = useStyles();
    return (
        <div className={className}>
            <Divider />
            <Flex className={css(S.RightSectionItem)}>
                <Label
                    variant="01"
                    weight="regular"
                    className={css(S.RightSectionLabel)}
                >
                    {label}
                </Label>
                <div className={css(S.RightSectionText)}>{children}</div>
            </Flex>
        </div>
    );
};
