import { useAppDisplayModeContext, useStyles } from '@archipro-design/aria';
import {
    FacebookCircleFill,
    InstagramLine,
    LinkedinBoxFill,
    TwitterFill,
    YoutubeFill,
    PinterestFill,
    TikTokFill,
} from '@archipro-design/icons';

import { MainInfoRightSectionItem } from './MainInfoRightSectionItem';

import * as S from './SocialLink.style';
import { useLoaderData } from '@remix-run/react';
import type { OverviewLoaderData } from '@modules/professional/page/OverviewPage';
import { useTracker } from '@archipro-website/tracker';
import { trackSocialMediaClick } from '~/modules/tracking/util/trackSocialMediaClick';
import type { SocialMedia } from '~/modules/professional/type';
import InfoSectionMobile from '~/modules/detail/components/info-section/InfoSectionMobile';

interface SocialLinksProps {
    disabledSocialLinks?: boolean;
}

export const SocialLinks: React.FC<SocialLinksProps> = ({
    disabledSocialLinks,
}) => {
    const { css } = useStyles({ disabledSocialLinks });
    const { mobile } = useAppDisplayModeContext();

    const { professionalOverview } = useLoaderData<OverviewLoaderData>();

    if (!professionalOverview) return null;

    const socialLinksDict = {
        fbLink: professionalOverview.PublicFacebookLink,
        igLink: professionalOverview.PublicInstagramLink,
        liLink: professionalOverview.PublicLinkedinLink,
        pnLink: professionalOverview.PublicPinterestLink,
        twLink: professionalOverview.PublicTwitterLink,
        ytLink: professionalOverview.PublicYoutubeLink,
        tkLink: professionalOverview.PublicTikTokLink,
    };

    const hasSocialMedia = Object.values(socialLinksDict).filter(
        (a) => a !== null
    ).length;

    const itemInfo = {
        itemID: professionalOverview.ID,
        title: professionalOverview.Title,
    };

    if (!hasSocialMedia) {
        return null;
    }

    const socialLinks = (
        <div
            className={css(
                mobile ? S.MobileIconWrapper : S.SocialLinkIconWrapper
            )}
        >
            {socialLinksDict?.fbLink && (
                <Icon
                    Icon={FacebookCircleFill}
                    href={socialLinksDict.fbLink}
                    label="Facebook"
                    {...itemInfo}
                />
            )}
            {socialLinksDict?.igLink && (
                <Icon
                    Icon={InstagramLine}
                    href={socialLinksDict.igLink}
                    label="Instagram"
                    {...itemInfo}
                />
            )}
            {socialLinksDict?.liLink && (
                <Icon
                    Icon={LinkedinBoxFill}
                    href={socialLinksDict.liLink}
                    label="LinkedIn"
                    {...itemInfo}
                />
            )}
            {socialLinksDict?.twLink && (
                <Icon
                    Icon={TwitterFill}
                    href={socialLinksDict.twLink}
                    label="Twitter"
                    {...itemInfo}
                />
            )}
            {socialLinksDict?.ytLink && (
                <Icon
                    Icon={YoutubeFill}
                    href={socialLinksDict.ytLink}
                    label="Youtube"
                    {...itemInfo}
                />
            )}
            {socialLinksDict?.pnLink && (
                <Icon
                    Icon={PinterestFill}
                    href={socialLinksDict.pnLink}
                    label="Pinterest"
                    {...itemInfo}
                />
            )}
            {socialLinksDict?.tkLink && (
                <Icon
                    Icon={TikTokFill}
                    href={socialLinksDict.tkLink}
                    label="TikTok"
                    {...itemInfo}
                />
            )}
        </div>
    );

    return mobile ? (
        <InfoSectionMobile className={css(S.MobileList)} label="Follow">
            {socialLinks}
        </InfoSectionMobile>
    ) : (
        <MainInfoRightSectionItem label="Follow">
            {socialLinks}
        </MainInfoRightSectionItem>
    );
};

export const Icon = ({
    Icon,
    href,
    label,
    itemID,
    title,
}: {
    Icon: typeof PinterestFill; // any icon.. doesn't matter. Just conviently grab the type we expect
    href: string;
    label: SocialMedia;
    itemID: number;
    title: string;
}) => {
    const tracker = useTracker();
    return (
        <a
            href={href}
            target={'_blank'}
            rel="noopener"
            onClick={() => {
                trackSocialMediaClick(label, itemID, title, tracker);
            }}
        >
            <Icon size={'1x'} />{' '}
        </a>
    );
};
