import type { StyleRule } from '@archipro-design/aria';
import { listItemClassName } from '@archipro-design/aria';
import { boxClassName } from '@archipro-design/aria';
import { labelClassName } from '@archipro-design/aria';
import { flexClassName } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';

export const SocialLinkIconWrapper: StyleRule<{
    disabledSocialLinks?: boolean;
}> = ({ theme, disabledSocialLinks }) => {
    return {
        display: 'flex',
        gap: pxToRem(14),
        pointerEvents: disabledSocialLinks ? 'none' : 'auto',

        ':hover': {
            cursor: 'pointer',
        },

        [`& ${svgIconClassName} svg`]: {
            display: 'inline-block',
            position: 'relative',
            '& path:first-child': {
                fill: 'transparent',
            },
            '& path': {
                fill: theme.siteVariables.colorScheme.default?.foreground,
            },
        },
    };
};

export const MobileList: StyleRule = () => ({
    [`& .${listItemClassName}`]: {
        gridTemplateColumns: '80rem 1fr',
    },
});

export const MobileIconWrapper: StyleRule = ({ theme }) => ({
    [`& .${labelClassName}`]: {
        letterSpacing: '0.03em',
        marginTop: pxToRem(3),
    },
    [`& .${flexClassName}`]: {
        marginTop: pxToRem(24),
        marginBottom: pxToRem(24),
    },

    [`& .${boxClassName}`]: {
        marginLeft: pxToRem(29),
        '& :nth-child(3)': {
            marginRight: 0,
        },
    },
    '& a': {
        display: 'inline-block',
        marginLeft: pxToRem(16),

        '& > span': {
            width: pxToRem(30),
            height: pxToRem(30),
        },

        '& svg': {
            '& path:first-child': {
                fill: 'transparent',
            },
            '& path:last-child': {
                fill: theme.siteVariables.colors.charcoal[300],
            },
        },
    },

    [`& .${flexClassName}`]: {
        margin: pxArrayToRem([16, 0, 5, 0]),
    },
});
