import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';

export const RightSectionItem: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([15, 0, 16, 0]),
    [theme.screen.laptop]: {
        margin: 0,
        padding: pxArrayToRem([20, 0]),
        minHeight: pxToRem(56),
        alignItems: 'flex-start',
    },
});

export const RightSectionLabel: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colorScheme.default?.foreground,

    fontSize: pxToRem(16),
    width: pxToRem(186),
    lineHeight: 1.5,
    letterSpacing: theme.siteVariables.letterSpacingNormal,

    [theme.screen.laptop]: {
        width: pxToRem(360),
        fontSize: pxToRem(16),
        lineHeight: 1,
    },
});

export const RightSectionText: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colorScheme.default?.foreground,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    marginTop: 0,
    marginBottom: 0,
    letterSpacing: theme.siteVariables.letterSpacingNormal,
    width: pxToRem(185),
    marginLeft: pxToRem(6),

    '& a': {
        color: theme.siteVariables.colorScheme.default?.foreground,
        ':hover': {
            color: theme.siteVariables.colorScheme.default?.foregroundHover,
        },
    },

    [theme.screen.laptop]: {
        fontSize: pxToRem(16),
        lineHeight: 1,
        letterSpacing: theme.siteVariables.letterSpacingMedium,
        fontWeight: theme.siteVariables.fontWeightRegular,
        width: 'inherit',
        marginLeft: 0,
    },
});
